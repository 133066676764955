import { Button, Col, Space, Spin } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useVideoConsultationRoom from './hooks/useVideoConsultationRoom';
import { ContentIframe } from '../../components/services-calendar/components/videoConsultation/styled';
import { videoConsultationParams } from '../../config/constants/videoConsultation';

const VideoConsultationRoom = () => {
  const { accountPrefix, username } = useParams();
  const { getVideoConsultationRoom, dataRoom, isLoading } = useVideoConsultationRoom();
  const location = window.location.href;
  
  const iframeUrl = useMemo(() => {
    if (dataRoom == null) return null;
    const params = {};
    if(dataRoom.params){
      Object.entries(dataRoom.params).forEach(([key, value]) => {
        const videoConsultationParam = videoConsultationParams[key];
        if (videoConsultationParam == null) return;
        params[videoConsultationParam.param] = value ? videoConsultationParam.enable : videoConsultationParam.disable;
      });
    }
    const queryString = new URLSearchParams(params).toString();
    return `${dataRoom.roomUrl}?${queryString}`;
  }, [dataRoom?.params, dataRoom?.roomUrl]);

  useEffect(() => {
    const data = {
      accountPrefix,
      username,
    };
    getVideoConsultationRoom(data);
  }, []);

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }

  if (!dataRoom?.roomUrl) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Col xs={24}>
        <ContentIframe>
          <Col xs={24}>
            <Button type="link" style={{ color: 'black' }}>
              <Space>
                <span>
                  <LinkOutlined />
                </span>
                <span>{location}</span>
              </Space>
            </Button>
          </Col>
          <Col xs={24}>
            <iframe
              title="videoconsultation-iframe"
              src={iframeUrl}
              allow="camera; microphone; fullscreen; speaker; display-capture"
              style={{ width: '100%', height: '650px', border: 'none' }}
            />
          </Col>
        </ContentIframe>
      </Col>
    </>
  );
};

export default VideoConsultationRoom;
