import { PageHeader, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import FeatherIcon from 'feather-icons-react';
import Pagination from 'antd/es/pagination';
import { RotationTimeModal } from './components/RotationTimeModal';
import { usePendingsBoardTable } from './hooks/usePendingsBoardTable';
import { ColumnSettingsModal } from './components/ColumnSettingsModal';
import { usePendingsBoard } from './hooks/usePendingsBoard';
import { PendingsBoardTable } from './components/PendingsBoardTable';
import { ServiceTypeFilterModal } from './components/ServiceTypeFilterModal';
import { ButtonClock, HeaderSpace, LogoImg, ReturnButton } from './Style';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { Main } from '../../container/styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import constants from '../../config/api/constants';

const PendingsBoard = () => {
  const { translate: t } = useDictionaryTranslation();
  const [isOpenRotationModal, setIsOpenRotationModal] = useState(false);
  const [isOpenListModal, setIsOpenListModal] = useState(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
  const { logo, staticPrefix } = useSelector((state) => {
    return {
      logo: state.auth.accountData?.menuLogoPlatform,
      staticPrefix: state.auth.accountData?.staticPrefix,
    };
  });
  const logoUrl = useMemo(() => {
    return !logo ? null : `${constants.s3Path}/${staticPrefix}/${logo}`;
  });
  const { settingData, getPendingsSettings } = usePendingsBoard();

  const serviceTypeFilter = useMemo(
    () => settingData?.serviceTypes?.map((item) => item._id),
    [settingData?.serviceTypes],
  );

  const tableData = usePendingsBoardTable({ rotationTime: settingData?.rotationTime, serviceTypes: serviceTypeFilter });

  const openListModal = () => {
    setIsOpenListModal(true);
  };

  const openFilterModal = () => {
    setIsOpenFilterModal(true);
  };

  const closeListModal = (success = false) => {
    if (success) {
      getPendingsSettings();
    }
    setIsOpenListModal(false);
  };

  const closeFilterModal = (success = false) => {
    if (success) {
      getPendingsSettings();
    }
    setIsOpenFilterModal(false);
  };

  const openRotationModal = () => {
    setIsOpenRotationModal(true);
  };

  const closeRotationModal = (success = false) => {
    if (success) {
      getPendingsSettings();
    }
    setIsOpenRotationModal(false);
  };

  return (
    <>
      <Space>
        <ReturnButton size="small">
          <Link to="/dashboard">
            <FeatherIcon icon="arrow-left" className="arrowIcon" />
          </Link>
        </ReturnButton>
        <LogoImg alt="account-logo" src={logoUrl ?? `${constants.s3UrlPath}/suite_assets/logo_suite.png`} />
      </Space>
      <Main>
        <HeaderSpace>
          <Space>
            <PageHeader title={`${tableData.data.total} ${t('services')}`} ghost />
          </Space>
          <Space size="large">
            <Pagination
              pageSize={tableData.data.pageSize}
              current={tableData.data.page}
              total={tableData.data.total}
              showSizeChanger={false}
              onChange={(page) => {
                tableData.getData({ page });
              }}
              showTotal={(all) => `${all} registros totales`}
            />
            <ButtonClock type="white" size="small" shape="circle" onClick={openFilterModal}>
              <FeatherIcon icon="filter" className="filterIcon" />
            </ButtonClock>
            <ButtonClock type="white" size="small" shape="circle" onClick={openListModal}>
              <FeatherIcon icon="list" className="listIcon" />
            </ButtonClock>
            <ButtonClock type="primary" size="small" shape="circle" onClick={openRotationModal}>
              <FeatherIcon icon="clock" className="clockIcon" />
            </ButtonClock>
          </Space>
        </HeaderSpace>
        <Cards headless>
          <PendingsBoardTable settingData={settingData} tableData={tableData.data} />
        </Cards>
      </Main>
      {isOpenListModal ? <ColumnSettingsModal show={isOpenListModal} handleClose={closeListModal} /> : null}
      {isOpenRotationModal ? <RotationTimeModal show={isOpenRotationModal} handleClose={closeRotationModal} /> : null}
      <ServiceTypeFilterModal show={isOpenFilterModal} handleClose={closeFilterModal} />
    </>
  );
};

export default PendingsBoard;
