import PropTypes from 'prop-types';
import { useMemo } from 'react';
import moment from 'moment';
import { Col, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ContentStatus } from './style';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { TagTable } from '../Style';
import { DataTable } from '../../../components/dataTable/data-table';
import { SearchButtonComponent } from '../../../components/buttons/search-button/SearchButton';
import { BulletIcon } from '../../../components/utilities/appIcons';
import { useColorList } from '../../../hooks/useColorList';

export const PendingsBoardTable = ({ tableData, isLoading, settingData }) => {
  const { translate: t } = useDictionaryTranslation();
  const { getColorById } = useColorList();

  const changeRowColor = (record) => {
    return {
      style: { background: record.colorHighlight }
    }
  }

  const bulletedText = (text, color, tooltipText) => {
    return (
      <>
        <Tooltip title={tooltipText}>
          <>{color ? <BulletIcon color={color ?? '#fffff'} /> : null}</>
        </Tooltip>
        {text && <span>{text}</span>}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        key: 'status',
        title: t('status'),
        show: settingData?.fields.status,
        width: '12%',
        render: (_, record) => (
          <>
            <ContentStatus
              style={{
                background: record.infoStatus?.color,
                opacity: '0.6',
              }}
            >
              <TagTable>{record.infoStatus?.name}</TagTable>
            </ContentStatus>
          </>
        ),
      },
      {
        key: 'id',
        dataIndex: 'privateId',
        title: t('Id'),
        show: settingData?.fields.id,
        width: '5%',
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: t('serviceName'),
        show: settingData?.fields.name,
        width: '26%',
      },
      {
        key: 'finalCustomer',
        title: t('finalCustomer'),
        show: settingData?.fields.finalCustomer,
        render: (_, record) => record.infoFinalCustomer?.fullname,
        width: '11%',
      },
      {
        key: 'customer',
        title: t('customer'),
        show: settingData?.fields.customer,
        render: (_, record) => record.infoAssignedCompany?.name,
        width: '11%',
      },
      {
        key: 'serviceType',
        title: t('serviceType'),
        show: settingData?.fields.serviceType,
        render: (_, record) => record.infoServiceType?.name,
        width: '25%',
      },
      {
        key: 'assignedDate',
        title: t('dateAndHour'),
        show: settingData?.fields.assignedDate,
        render: (_, record) => moment(record.start).format('DD/MM/YYYY HH:mm'),
        dataIndex: 'start',
        width: '12%',
      },
      {
        key: 'creationDate',
        title: t('cretionDate'),
        show: settingData?.fields.creationDate,
        render: (_, record) => moment(record.createdAt).format('DD/MM/YYYY HH:mm'),
        dataIndex: 'start',
        width: '12%',
      },
      {
        key: 'technician',
        title: t('technician'),
        show: settingData?.fields.assignedUser,
        render: (_, record) => record.infoAssignedUser?.profile.name,
        width: '8%',
      },
      {
        key: 'serviceTags',
        title: t('tags'),
        show: settingData?.fields.serviceTags,
        render: (_, record) => {
          return (
            <Row gutter={[2, 2]} wrap>
              {record.serviceTags.map((item) => {
                return (
                  <Col key={item?._id}>
                    {bulletedText(null, getColorById(item.idColor), `${item.label} (${item.value})`)}
                  </Col>
                );
              })}
            </Row>
          )
        },
        width: '6%',
      },
      {
        show: true,
        render: (_, record) => (
          <Link to={`/services-detail/${record?._id}/summary`}>
            <SearchButtonComponent primaryColor="lightgrey" textColor="black" width="25px" />
          </Link>
        ),
        width: '1%',
      },
    ],
    [settingData],
  );

  return (
    <>
      <DataTable
        rowKey="_id"
        columns={columns.filter((column) => column.show)}
        isLoading={isLoading}
        tableData={tableData}
        showPagination={false}
        onRow={changeRowColor}
        headerData={{
          show: false,
        }}
      />
    </>
  );
};

PendingsBoardTable.propTypes = {
  settingData: PropTypes.object,
  tableData: PropTypes.object,
  isLoading: PropTypes.bool,
};
