import { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { videoCallService } from "../../../services/videoCallService";
import { setDictionary } from '../../../redux/dictionary/actionCreator';

export const useVideoCall = ({ idService }) => {
  const dispatch = useDispatch();
  const [videoCallData, setVideoCallData] = useState(null);
  const [signToken, setSignToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  
  const getVideoCall = useCallback(async () => {
    if (!idService) {
      return
    };
    try {
      const { videoCall, token, dictionary } = await videoCallService.getVideoCallData(idService);
      dispatch(setDictionary(dictionary));
      setVideoCallData(videoCall);
      setSignToken(token);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [idService]);

  const signVideoCallDocument =  useCallback(async (signData) => {
    setIsLoading(true);
    try {
      const data = await videoCallService.signVideoCallDocument(signData, signToken);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      setIsLoading(false);
    }
  },[signToken]);


  useEffect(() => {
    setIsLoading(true);
    getVideoCall();
  }, [getVideoCall]);

  return {
    videoCallData,
    isLoading,
    signVideoCallDocument
  };
}