import Styled from 'styled-components';

const HeaderImages = Styled.div`
  padding-top: 10px;
  @media only screen and (min-width: 1000px){
    background: #fff;
    width: 100%;
  }
  img {
    display: flex;
  }
  .account-logo {
    @media only screen and (max-width: 999px){
      width: 40%;
      height: 40px;
      margin-left: 10%;
    }
    @media only screen and (min-width: 1000px){
      height: 40px;
      margin-left: 10%;
    }
  }
`;

const HeaderContainer = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`;

export {
  HeaderImages,
  HeaderContainer
};
