import { Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FormTechnician from './FormTechnician';
import FormInterested from './FormInterested';
import FormOperator from './FormOperator';
import { SelectUserProfile } from './SelectProfile';
import { UsertypeDescription } from './UsertypeDescription';
import { UsernameDescription } from './UsernameDescription';
import useVideoConsultation from './fieldsUrls/hooks/useVideoConsultation';
import { PasswordInput } from './PasswordInput';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { useNotifications } from '../../../hooks/useNotifications';
import useUser from '../../Users/hook/useUser';
import { TitleForm } from '../../../container/styled';
import { FormWrapperPage } from '../../../components/formWrapper/form-wrapper';
import { profileTypes } from '../../../config/constants/userConstants';

const FormUserDetails = () => {
  const [form] = Form.useForm();
  const [userType, setUserType] = useState(null);
  const [username, setUsername] = useState('');
  const [switchSkills, setSwitchSwills] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [formHasError, setFormHasError] = useState(false);
  const [selectedHeadquarters, setSelectedHeadquarters] = useState([]);
  const [viewAllServices, setViewAllServices] = useState(false);
  const { userId } = useParams();
  const { translate: t } = useDictionaryTranslation();
  const { updateUser, userData, createUser, hasError, isLoading } = useUser({ userId });
  const { profile } = userData;
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { prefix, accountModules } = useSelector((state) => ({
    accountModules: state.auth.accountModules,
    prefix: state.auth.accountData?.prefix,
  }));
  const userCreating = userId == null;
  const videoConsultationRoom = useVideoConsultation({ getDataOnInit: accountModules.videoConsultationRoom, userId });

  const onFieldsChange = useCallback((_, fields) => {
    const hasErrors = fields.some(({ errors }) => errors.length > 0);
    setFormHasError(hasErrors);
  }, []);

  const onHandleChange = (event) => {
    setUserType(event?.value);
  };

  const onHandleSkills = (event) => {
    setSwitchSwills(event);
  };

  const getProfilesFields = () => {
    const profileComponents = {
      [profileTypes.TECHNICIAN.id]: () => {
        return (
          <FormTechnician
            onHandleSkills={onHandleSkills}
            switchSkills={switchSkills}
            userData={userData}
            videoConsultationRoom={videoConsultationRoom}
          />
        );
      },
      [profileTypes.INTERESTED.id]: () => {
        return <FormInterested />;
      },
      [profileTypes.OPERATOR.id]: () => {
        return (
          <FormOperator
            headquarters={selectedHeadquarters}
            viewAllServices={viewAllServices}
            onChangeHeadquarters={setSelectedHeadquarters}
            onChangeViewAllServices={setViewAllServices}
          />
        );
      },
    };
    return profileComponents[userType]?.();
  };

  const onSubmit = async () => {
    const values = form.getFieldValue();
    const assignableServiceProfiles = [];
    if (values.canAssignServicesToTechnician) {
      assignableServiceProfiles.push(profileTypes.TECHNICIAN.id);
    }
    if (values.canAssignServicesToOperator) {
      assignableServiceProfiles.push(profileTypes.OPERATOR.id);
    }

    const data = {
      canCreatePetitions: Boolean(values.canCreatePetitions),
      username: values.username?.replace(/\W/g, ''),
      email: values.emails,
      password: values.password,
      identification: values.identification,
      name: values.name,
      idProfile: values.userType.value,
      cellular: values.phone,
      assignedCompany: values.assignedCompany,
      // canViewServicesHistory: values.allowToViewHistory,
      enableAvailabilitySchedule: Boolean(values.enableSchedulingTimes),
      // canCreateServices: values.createServicesFromMobile,
      administrateAdvancedAvailability: Boolean(values.administrateAdvancedAvailability),
      phone: values.phone,
      schedulingTimes: values.schedulingTimes,
      scheduleLocks: values.scheduleLocks,
      idResourcesToAdmin: values.resources?.map((resource) => resource.value),
      editInfoCustomers: values.editInfoCustomers,
      administrateServices: Boolean(values.administrateServices),
      seeAllServices: Boolean(values.seeAllServices),
      servicesTypesAssigned: values.servicesTypesAssigned?.map((option) => option.value),
      administrateClients: Boolean(values.administrateClients),
      specialities: values.skills.map((skill) => skill.value),
      companies: values.assignedCompanies.map((company) => company.value),
      idsHeadquarters: values.headquarters.map((headquarter) => headquarter.value),
      canChangeServiceStatus: Boolean(values.canChangeServiceStatus),
      adminAllUsers: Boolean(profile.adminAllUsers),
      canViewServicesHistory: Boolean(values.canViewServicesHistory),
      canEditFinalCustomer: Boolean(values.canEditFinalCustomer),
      canManageCustomers: Boolean(values.canManageCustomers),
      canCreateServices: Boolean(values.canCreateServices),
      isMandatoryGPS: Boolean(values.isMandatoryGPS),
      enableSupportChat: Boolean(values.enableSupportChat),
      canCreateIncidentNotes: Boolean(values.canCreateIncidentNotes),
      canManageCustomersMobile: profile.canManageCustomersMobile,
      assignableServiceProfiles,
    };
    try {
      if (userId != null) {
        await updateUser(data);
        if (videoConsultationRoom.dataRoom != null && accountModules.videoConsultationRoom) {
          await videoConsultationRoom.saveRoomData(userId, videoConsultationRoom.dataRoom);
        }
        showSuccessNotification({
          title: 'success',
          description: 'successUpdatingRecord',
        });
      } else {
        await createUser(data);
        showSuccessNotification({
          title: 'success',
          description: 'successCreatingRecord',
        });
      }
      setSuccessCreate(true);
    } catch (error) {
      console.error(error);
      showErrorNotification({
        title: 'error',
        description: userId == null ? 'errorCreatingRecord' : 'errorUpdatingRecord',
      });
    }
  };
  const onHandleUsername = (event) => {
    setUsername(event.target.value?.replace(/\W/g, ''));
  };
  useEffect(() => {
    const unPrefixUsername = userData.username?.replace(`${prefix}.`, '');
    const getItems = ({ items, valueKey = '_id', labelKey = 'name' }) => {
      if (!Array.isArray(items)) return [];
      return items.map((item) => ({
        value: item?.[valueKey],
        label: item?.[labelKey],
      }));
    };
    const headquarters = getItems({ items: userData.profile?.idsHeadquarters });
    const formData = {
      userType: {
        value: userData.profile?.idProfile,
      },
      schedulingTimes: userData.schedulingTimes,
      scheduleLocks: userData.scheduleLocks,
      skills: getItems({ items: userData.profile?.specialities }),
      username: unPrefixUsername ?? '',
      emails: userData.emails?.[0].address,
      phone: userData.profile?.phone,
      name: userData.profile?.name,
      identification: userData.profile?.identification,
      assignedCompany: userData.profile?.assignedCompany,
      allowToViewHistory: userData.profile?.canViewServicesHistory,
      enableSchedulingTimes: userData.profile?.enableAvailabilitySchedule,
      administrateAdvancedAvailability: userData.profile.administrateAdvancedAvailability,
      headquarters,
      editInfoCustomers: userData.profile?.editInfoCustomers,
      administrateServices: userData.profile?.administrateServices,
      seeAllServices: userData.profile?.seeAllServices,
      servicesTypesAssigned: getItems({ items: userData.servicesTypesAssigned }),
      assignedCompanies: getItems({ items: userData.profile?.companies }),
      createServicesFromMobile: userData.profile?.canCreateServices,
      administrateClients: userData.profile?.administrateClients,
      resources: userData?.resourcesToAdmin?.map((item) => ({ value: item._id, label: item.profile.name })),
      password: '',
      canChangeServiceStatus: userData.profile?.canChangeServiceStatus,
      canCreateServices: userData.profile?.canCreateServices,
      adminAllUsers: userData.profile?.adminAllUsers,
      canEditFinalCustomer: userData.profile?.canEditFinalCustomer,
      canCreateIncidentNotes: userData.profile?.canCreateIncidentNotes,
      canManageCustomers: userData.profile?.canManageCustomers,
      isMandatoryGPS: userData.profile?.isMandatoryGPS,
      enableSupportChat: userData.profile?.enableSupportChat,
      canViewServicesHistory: userData.profile?.canViewServicesHistory,
      canAssignServicesToTechnician: userData.profile?.assignableServiceProfiles?.includes(profileTypes.TECHNICIAN.id),
      canAssignServicesToOperator: userData.profile?.assignableServiceProfiles?.includes(profileTypes.OPERATOR.id),
    };
    form.setFieldsValue(formData);
    setViewAllServices(userData.profile.seeAllServices);
    setUserType(userData.profile?.idProfile);
    setUsername(unPrefixUsername ?? '');
    setSwitchSwills(userData.profile?.enableAvailabilitySchedule);
    setSelectedHeadquarters(headquarters);
  }, [userData, form]);

  if (successCreate || hasError) return <Redirect to="/settings/users" />;

  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <TitleForm>{t('userTypeConfigBase')}</TitleForm>
        <h4>{t('configUserMessage')}</h4>
      </div>
      <Spin spinning={isLoading}>
        <FormWrapperPage>
          <Form
            layout="vertical"
            name="createUserForm"
            onFinish={onSubmit}
            form={form}
            autoComplete="off"
            scrollToFirstError
            onFieldsChange={onFieldsChange}
          >
            <Row>
              <Col xs={24} md={12}>
                {userData.profile?.idProfile !== '1' ? (
                  <Form.Item
                    name="userType"
                    label={t('userType')}
                    rules={[{ required: true, message: t('userTypeRequired') }]}
                  >
                    <SelectUserProfile onChange={onHandleChange} />
                  </Form.Item>
                ) : null}
              </Col>
              <Col xs={24} md={12}>
                <UsertypeDescription userType={userType} />
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="username"
                  label={t('username')}
                  rules={[{ required: true, message: t('usernameRequired') }]}
                >
                  <Input onChange={onHandleUsername} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <UsernameDescription username={username} userType={userType} />
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="identification"
                  label={t('identification')}
                  rules={[{ required: true, message: t('identificationRequired') }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="name" label={t('name')} rules={[{ required: true, message: t('nameRequired') }]}>
                  <Input />
                </Form.Item>
              </Col>
              {userId ? null : (
                <>
                  <Col xs={24} md={12}>
                    <PasswordInput name="password" label={t('passwordLabel')} required={userCreating} />
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="passwordConfirmation"
                      label={t('confirmPassword')}
                      rules={[
                        { required: userCreating, message: t('passwordRequiredMessage') },
                        ({ getFieldValue }) => ({
                          message: t('passwordAndConfirmationsMustBeEquals'),
                          validator: (_, value) => {
                            const currentPassword = getFieldValue('password') ?? '';
                            const currentValue = value ?? '';
                            if (currentValue !== currentPassword) return Promise.reject();
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col xs={24} md={12}>
                <Form.Item
                  name="emails"
                  label={t('email')}
                  rules={[{ required: true, type: 'email', message: t('emailRequired') }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  label={t('mobilePhone')}
                  rules={[{ required: true, message: t('mobilePhoneRequired') }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {getProfilesFields()}
            </Row>
            <Row justify="end">
              <Button form="createUserForm" htmlType="submit" type="primary" disabled={formHasError}>
                {t('save')}
              </Button>
            </Row>
          </Form>
        </FormWrapperPage>
      </Spin>
    </>
  );
};

export default FormUserDetails;
