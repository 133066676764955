import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { CustomSwitch } from '../../../SelfScheduling/components/form/editScheduleBlocks/CustomSwitch';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { videoConsultationParams } from '../../../../config/constants/videoConsultation';

const ConsultationParameters = ({ params, onChange }) => {
  const { translate: t } = useDictionaryTranslation();
  const [currentVideoConsultationParams, setCurrentVideoConsultationParams] = useState({});

  useEffect(() => {
    if (params != null) {
      setCurrentVideoConsultationParams(params);
    }
  }, [params]);

  const handleSwitchChange = (key) => (checked) => {
    setCurrentVideoConsultationParams((currentParams) => {
      const newParams = {
        ...currentParams,
        [key]: checked,
      };
      onChange(newParams);
      return newParams;
    });
  };

  return (
    <Row style={{ marginTop: '20px' }}>
      {Object.keys(videoConsultationParams).map((key) => (
        <Col xs={12} md={10} span={24} style={{ marginBottom: '10px' }} key={key}>
          <Row>
            <CustomSwitch checked={currentVideoConsultationParams[key] || false} onChange={handleSwitchChange(key)} />
            <span>{t(videoConsultationParams[key].title)}</span>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

ConsultationParameters.propTypes = {
  onChange: PropTypes.func,
  params: PropTypes.shape({
    disableAudio: PropTypes.bool,
    disableVideo: PropTypes.bool,
    chat: PropTypes.bool,
    disablePeopleList: PropTypes.bool,
    disableLeaveButton: PropTypes.bool,
    recording: PropTypes.bool,
    screenshare: PropTypes.bool,
  }),
};

export default ConsultationParameters;
