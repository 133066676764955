import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Modal, Row, Space, Typography } from "antd";
import { useImportCustomList } from './hooks/useImportCustomList';
import { ButtonSpace } from './Style';
import { useDictionaryTranslation } from "../../../../hooks/useDictionaryTranslation";
import { FileUploadButton } from '../../../../components/file-upload-button/file-upload-button';
import constants from '../../../../config/api/constants';

export const ImportListModal = ({ show, handleClose, customListId }) => {
  const { translate: t } = useDictionaryTranslation();
  const [fileList, setFileList] = useState([]);
  const { importList } = useImportCustomList();
  const { idAcount } = useSelector((state) => {
    return {
      idAcount: state.auth.accountData._id,
    };
  });
  const { Link } = Typography;

  const closeModal = () => {
    handleClose(true)
  }

  const uploadImportList = useCallback ((file) => {
    importList(customListId, file, idAcount);
  }, [ importList, idAcount, customListId])

  return (
    <Modal
      title={t('importCustomList')}
      centered
      width="40%"
      height="70%"
      onCancel={closeModal}
      destroyOnClose
      open={show}
      footer={null}
      style={{ paddingBottom: '0px' }}
    >
      <Space direction="vertical" size="middle">
        <Row>
          <Col xs={24}>
            <p>{t('importCustomListText')} <Link href={constants.customListImportTemplate}>{t('here')}</Link> {t('importCustomListTextModal')}</p>
          </Col>
        </Row>
        <Row justify="end">
          <ButtonSpace>
            <FileUploadButton
              buttonDirection="right"
              text={t('import')}
              color="primary"
              maxCount={1}
              style={{ marginRight: '20px !important' }}
              fileList={fileList}
              setFileList={setFileList}
              onBeforeUpload={uploadImportList}
            />
          </ButtonSpace>
        </Row>
      </Space>
    </Modal>
  )
};

ImportListModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  customListId: PropTypes.string,
};
