import React from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import { FormWrapper } from '../../../components/formWrapper/form-wrapper';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { PasswordInput } from '../../UserDetails/components/PasswordInput';
import useUser from '../hook/useUser';
import { useNotifications } from '../../../hooks/useNotifications';

const ModalPassword = ({ userId, show, closeModal }) => {
  const { translate: t } = useDictionaryTranslation();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [form] = Form.useForm();
  const { updatePassword } = useUser({ userId });

  const onSubmit = async () => {
    const values = form.getFieldValue();
    const dataForm = {
      userId,
      password: values.password,
    };
    try {
      await updatePassword(dataForm);
      showSuccessNotification({
        title: 'success',
        description: 'successUpdatingRecord',
      });
      closeModal();
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorUpdatingRecord',
      });
    }
  };

  return (
    <>
      <Modal
        title={t('updatePasswordText')}
        centered
        destroyOnClose
        width="40%"
        height="70%"
        onCancel={closeModal}
        open={show}
        footer={
          <div>
            <Button form="createUserForm" key="submit" htmlType="submit" type="primary">
              {t('save')}
            </Button>
          </div>
        }
      >
        <FormWrapper>
          <Form
            layout="vertical"
            name="createUserForm"
            onFinish={onSubmit}
            form={form}
            //   form={form} onFinish={handleSubmit}
          >
            <Row>
              <Col xs={24}>
                <PasswordInput name="password" label={t('passwordLabel')} required />
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="passwordConfirmation"
                  label={t('confirmPassword')}
                  rules={[
                    { required: true, message: t('passwordRequiredMessage') },
                    ({ getFieldValue }) => ({
                      message: t('passwordAndConfirmationsMustBeEquals'),
                      validator: (_, value) => {
                        const currentPassword = getFieldValue('password') ?? '';
                        const currentValue = value ?? '';
                        if (currentValue !== currentPassword) return Promise.reject();
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default ModalPassword;

ModalPassword.propTypes = {
  userId: PropTypes.string,
  show: PropTypes.bool,
  closeModal: PropTypes.bool,
};
