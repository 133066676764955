import { useSelector } from 'react-redux';
import { Col, Divider, Form, Collapse, Row } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ScheduleLocks } from './ScheduleLocks';
import SchedulingTimesField from './SchedulingTimes';
import FormTeleconsultation from './FormTeleconsultation';
import { CustomSwitch } from '../../SelfScheduling/components/form/editScheduleBlocks/CustomSwitch';
import { SelectSkills } from '../../../components/skills/SelectSkills';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { SelectHeadquarters } from '../../../components/headquarters/SelectHeadquarters';
import { theme } from '../../../config/theme/themeVariables';
import { TitleFormDefault } from '../../../container/styled';
import { solutionConstants } from '../../../config/constants/solutionsConstants';

const { Panel } = Collapse;

const allowedMobileSettingsBySolutionIds = [
  solutionConstants.TAPPTUS.id,
  solutionConstants.MIEMERGENCIA.id,
  solutionConstants.APHMED.id,
];

const FormTechnician = ({ onHandleSkills, switchSkills, userData, videoConsultationRoom }) => {
  const { translate: t } = useDictionaryTranslation();
  const { accountModules, solutionId } = useSelector((state) => ({
    accountModules: state.auth.accountModules,
    solutionId: state.auth.accountData.solutionId,
  }));

  return (
    <>
      {allowedMobileSettingsBySolutionIds.includes(solutionId) ? (
        <>
          <Col xs={24} md={12}>
            <Form.Item name="allowToViewHistory" label={t('allowToViewHistory')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="createServicesFromMobile" label={t('createServicesFromMobile')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
          </Col>
        </>
      ) : null}

      <Col xs={24} md={12}>
        <Form.Item name="canChangeServiceStatus" label={t('infoServiceStatusFromMobile')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
      </Col>

      <Divider />
      <Col xs={24} md={20}>
        <TitleFormDefault>{t('skills')}</TitleFormDefault>
        <span>{t('skillsDescription')}</span>
        <Link to="/settings/skills/">
          <span style={{ color: theme['primary-color'] }}>{t('skillsDescriptionLink')}</span>
        </Link>
        <span>{t('skillsDescriptionFinish')}</span>
        <Form.Item name="skills">
          <SelectSkills multiple />
        </Form.Item>
      </Col>
      <Col xs={24} md={20}>
        <TitleFormDefault>{t('headquarter')}</TitleFormDefault>
        <h4>{t('headquarterDescriptionTechnician')}</h4>
        <Form.Item name="headquarters">
          <SelectHeadquarters multiple />
        </Form.Item>
      </Col>
      {accountModules.mobileAppSettings ? (
        <Col xs={24} md={24}>
          <Collapse accordion style={{ marginBottom: '3%' }}>
            <Panel header={t('appMobileOptions')}>
              <Row>
                <>
                  <Col xs={12}>
                    <Form.Item name="canCreateServices" label={t('createServicesTextOptions')} valuePropName="checked">
                      <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="canViewServicesHistory"
                      label={t('historyServiceTextSwitch')}
                      valuePropName="checked"
                    >
                      <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="canManageCustomers" label={t('createCustomerTextOptions')} valuePropName="checked">
                      <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="canEditFinalCustomer"
                      label={t('createFinalCustomerTextOptions')}
                      valuePropName="checked"
                    >
                      <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="isMandatoryGPS" label={t('gpsTextswitch')} valuePropName="checked">
                      <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="enableSupportChat" label={t('enableSupportChat')} valuePropName="checked">
                      <CustomSwitch checked checkedText={t('yes')} unCheckedText={t('no')} />
                    </Form.Item>
                  </Col>
                </>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      ) : null}

      {userData?._id && accountModules.videoConsultationRoom && !videoConsultationRoom.isLoading ? (
        <Col xs={24} md={24}>
          <FormTeleconsultation
            idUser={userData._id}
            roomData={videoConsultationRoom.dataRoom}
            onChangeRoomData={videoConsultationRoom.updateRoomData}
          />
        </Col>
      ) : null}

      {!accountModules.advancedAvailability ? (
        <>
          <Col xs={24} md={20}>
            <TitleFormDefault>{t('hoursavailability')}</TitleFormDefault>
            <h4>{t('hoursdescription')}</h4>
            <Form.Item name="enableSchedulingTimes" valuePropName="checked">
              <CustomSwitch
                checked={switchSkills}
                onChange={onHandleSkills}
                checkedText={t('yes')}
                unCheckedText={t('no')}
              />
            </Form.Item>
          </Col>

          {switchSkills ? (
            <Col xs={24} md={20}>
              <Form.Item name="schedulingTimes">
                <SchedulingTimesField />
              </Form.Item>
              <Divider />
              <Form.Item name="scheduleLocks">
                <ScheduleLocks />
              </Form.Item>
            </Col>
          ) : null}
        </>
      ) : null}
    </>
  );
};

FormTechnician.propTypes = {
  onHandleSkills: PropTypes.func,
  switchSkills: PropTypes.bool,
  userData: PropTypes.object,
  videoConsultationRoom: PropTypes.shape({
    isLoading: PropTypes.bool,
    updateRoomData: PropTypes.func,
    dataRoom: PropTypes.object,
  }),
};

export default FormTechnician;
