import React, { useRef, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { Button } from '../buttons/buttons';

const SignaturePad = ({ onSave, disabled, signatureURL }) => {
  const { translate: t } = useDictionaryTranslation();
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const containerRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const points = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;

    if (container) {
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
    }

    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    contextRef.current = ctx;
  }, []);

  const startDrawing = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    points.current = [{ x: offsetX, y: offsetY }];
    setIsDrawing(true);
  };

  const drawCurve = () => {
    const ctx = contextRef.current;
    if (points.current.length < 2) return;

    ctx.beginPath();
    ctx.moveTo(points.current[0].x, points.current[0].y);

    for (let i = 1; i < points.current.length - 1; i += 1) {
      const midPointX = (points.current[i].x + points.current[i + 1].x) / 2;
      const midPointY = (points.current[i].y + points.current[i + 1].y) / 2;
      ctx.quadraticCurveTo(points.current[i].x, points.current[i].y, midPointX, midPointY);
    }

    ctx.stroke();
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = e.nativeEvent;
    points.current.push({ x: offsetX, y: offsetY });

    drawCurve();
  };

  const stopDrawing = () => {
    setIsDrawing(false);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;

    let top = canvas.height
    let left = canvas.width
    let bottom = 0
    let right = 0

    for (let y = 0; y < canvas.height; y += 1) {
      for (let x = 0; x < canvas.width; x += 1) {
        const index = (y * canvas.width + x) * 4;
        if (pixels[index + 3] > 0) {
          if (x < left) left = x;
          if (x > right) right = x;
          if (y < top) top = y;
          if (y > bottom) bottom = y;
        }
      }
    }

    if (right - left > 0 && bottom - top > 0) {
      const newWidth = right - left;
      const newHeight = bottom - top;
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = newWidth;
      croppedCanvas.height = newHeight;
      const croppedCtx = croppedCanvas.getContext("2d");

      croppedCtx.putImageData(
        ctx.getImageData(left, top, newWidth, newHeight),
        0,
        0
      );

      const dataURL = croppedCanvas.toDataURL("image/png");
      onSave(dataURL);
      return;
    }
    onSave('');
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    contextRef.current.clearRect(0, 0, canvas.width, canvas.height);
    points.current = [];
  };

  return (
    !disabled ?
      <div ref={containerRef} style={{ width: "100%", height: "100%", border: "1px solid #e4e6ef", borderRadius: "5px" }}>
        <canvas
          ref={canvasRef}
          style={{ width: "100%", height: "100%" }}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
        <Row justify="end">
          <Button onClick={clearCanvas} type="gray" style={{ marginTop: '10px' }}>
            {t('clear')}
          </Button>
        </Row>
      </div>
      :
      <img src={`${signatureURL}`} alt="sign" />
  );
};

SignaturePad.propTypes = {
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  signatureURL: PropTypes.string,
};

export default SignaturePad;
