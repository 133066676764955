const SIGNATURE_TYPES = [
  { id: 1, name: 'finalCustomer' },
  { id: 2, name: 'companion' },
]

const TEMPLATE_VALUES_FORMAT = {
  document_signature_day: { format: "DD" },
  document_signature_month: { format: "MM" },
  document_signature_year: { format: "YYYY" },
  document_signature_date: { format: "DD MMM YYYY" },
  document_signature_hour: { format: "HH:mm" },
}

export { SIGNATURE_TYPES, TEMPLATE_VALUES_FORMAT };
