import { useCallback, useEffect, useState } from 'react';
import { videoConsultationService } from '../../../../../services/videoConsultationService';
import { debounceFunction } from '../../../../../utility/utility';
import { useNotifications } from '../../../../../hooks/useNotifications';

const useVideoConsultation = ({ getDataOnInit = false, userId } = {}) => {
  const [dataRoom, setDataRoom] = useState({});
  const [isLoading, setIsLoading] = useState(getDataOnInit);
  const { showErrorNotification } = useNotifications();

  const createRoom = async (idUser) => {
    setIsLoading(true);
    try {
      const data = await videoConsultationService.createRoom(idUser);
      setDataRoom(data.userRoomData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRoomData = useCallback(
    debounceFunction(async (idUser) => {
      setIsLoading(true);
      try {
        const data = await videoConsultationService.getRoom(idUser);
        console.log({ data });
        setDataRoom(data.videoConsultationRoom);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [],
  );

  const saveRoomData = async (idUser, newData) => {
    setIsLoading(true);
    try {
      const data = await videoConsultationService.saveRoom(idUser, newData);
      setDataRoom(data.videoConsultationRoom);
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorUpdatingVideoConsultationRoom',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateRoomData = (newParams) => {
    setDataRoom((current) => ({ ...current, ...newParams }));
  };

  useEffect(() => {
    if (getDataOnInit) {
      getRoomData(userId);
    }
  }, []);

  return {
    createRoom,
    dataRoom,
    getRoomData,
    saveRoomData,
    updateRoomData,
    isLoading,
  };
};

export default useVideoConsultation;
