import { useCallback, useState } from 'react';
import { videoConsultationService } from '../../../services/videoConsultationService';

const useVideoConsultationRoom = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataRoom, setDataRoom] = useState(null);

  const getVideoConsultationRoom = useCallback(async (data) => {
    setIsLoading(true);
    try {
      const room = await videoConsultationService.getPublicRoom(data);
      setDataRoom(room.dataRoom);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    getVideoConsultationRoom,
    dataRoom,
    isLoading,
  };
};

export default useVideoConsultationRoom;
