import { DictionariesService } from './dictionariesService';
import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class videoCallService {
  static async getVideoCallData(idservice) {
    const { data } = await DataService.get(`${API.videoCall.root}/${idservice}`);
    let dictionary = {}
    if (data?.data?.dictionary) {
      dictionary = DictionariesService.transformDictionary(data?.data.dictionary)
    }
    return {
      videoCall: data?.data,
      token: data?.token,
      dictionary
    };
  }

  static async signVideoCallDocument(signData, signToken) {
    const customHeader = {
      Authorization: `Bearer ${signToken}`,
    }
    const { data } = await DataService.post(`${API.videoCall.root}/${API.videoCall.signDocument}`, signData, customHeader);
    return data;
  }
}

export { videoCallService };
