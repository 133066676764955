import React, { useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { DragOutlined, FormOutlined, DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { ModalEditNode } from './ModalEditNode';
import { ChildNodesWrapper, ContentItem, ItemsCrud, NodeName } from './style';
import { useListNodesActions } from './hooks/useListNodesActions';
import { useNotifications } from '../../../../hooks/useNotifications';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';

const CustomListNode = ({ nodeData, renderChildNodes, removeNode, saveChanges, setChildNodes }) => {
  const { addNode } = useListNodesActions({ nodes: nodeData?.nodes ?? [], saveNodesChanges: setChildNodes });
  const { showConfirmNotification } = useNotifications();
  const { translate: t } = useDictionaryTranslation();
  const [modalEditNodeData, setModalEditNodeData] = useState({
    show: false,
    nodeData: null,
    actionHandler: null,
  });
  const { atributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: nodeData.id,
  });

  const [showChildNodes, setShowChildNodes] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const toggleChildNodes = () => {
    setShowChildNodes((value) => !value);
  };

  const closeNodesModal = () => {
    setModalEditNodeData({ show: false });
  };

  const onDeleteNodeList = () => {
    showConfirmNotification({
      title: t('areYouSure'),
      content: t('recordWillBeRemoved', { recordDescription: nodeData.title }),
      onOk: async () => {
        await removeNode(nodeData.id);
      },
    });
  };

  const saveNodeChanges = async (nodeId, values) => {
    await saveChanges(nodeId, values);
    closeNodesModal();
  };

  const handleAddNode = () => {
    const saveChildNode = async (_, values) => {
      await addNode(values);
      setShowChildNodes(true);
      closeNodesModal();
    };
    setModalEditNodeData({
      show: true,
      actionHandler: saveChildNode,
      nodeData: null,
    });
  };

  const onEditCurrentNode = () => {
    setModalEditNodeData({ show: true, actionHandler: saveNodeChanges, nodeData });
  };

  return (
    <>
      <div ref={setNodeRef} {...atributes} {...listeners} style={style}>
        <ContentItem>
          <Row>
            {nodeData.nodes?.length > 0 ? (
              <Button
                onClick={toggleChildNodes}
                icon={<DragOutlined style={{ fontSize: '12px' }} className="iconDrag" />}
              />
            ) : null}
            <Col md={14}>
              <NodeName>{nodeData.title}</NodeName>
            </Col>
            <Col md={7}>
              <p>{nodeData.description}</p>
            </Col>

            <Col md={3}>
              <Row justify="end">
                <ItemsCrud>
                  <Button type="secondary" onClick={handleAddNode} icon={<PlusSquareOutlined />} />
                  <Button type="secondary" onClick={onEditCurrentNode} icon={<FormOutlined />} />
                  <Button type="secondary" onClick={onDeleteNodeList} icon={<DeleteOutlined />} />
                </ItemsCrud>
              </Row>
            </Col>
          </Row>
        </ContentItem>
        {showChildNodes ? <ChildNodesWrapper>{renderChildNodes(nodeData.nodes)}</ChildNodesWrapper> : null}
      </div>
      {modalEditNodeData.show ? (
        <ModalEditNode
          show={modalEditNodeData.show}
          handleClose={closeNodesModal}
          nodeData={modalEditNodeData.nodeData}
          saveNode={modalEditNodeData.actionHandler}
        />
      ) : null}
    </>
  );
};

CustomListNode.propTypes = {
  nodeData: PropTypes.object,
  renderChildNodes: PropTypes.func,
  removeNode: PropTypes.func,
  saveChanges: PropTypes.func,
  setChildNodes: PropTypes.func,
};

export default CustomListNode;
