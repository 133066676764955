import { Button, Modal, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { IconWrapper, Message } from './style';

const AccountLockoutModal = ({ show, onClose }) => {
  return (
    <Modal open={show} footer={null} onCancel={onClose} width={530} centered>
      <Row justify="center">
        <IconWrapper>
          <FeatherIcon size={28} icon="alert-triangle" color="white"/>
        </IconWrapper>
        <Row justify="center">
          <Message>
            <p>
              Tu cuenta está en modo visualización, no podrás crear Servicios. Comunícate con un asesor para activarla
              nuevamente
            </p>
          </Message>
        </Row>
        <Row justify="center" style={{ marginTop: '30px' }}>
          <Button type="primary" onClick={onClose}>
            Continuar
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};

export default AccountLockoutModal;

AccountLockoutModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};
