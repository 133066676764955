import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Col, Form, Row, Spin, Input, Radio } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { debounceFunction } from "../../utility/utility";
import { Cards } from '../cards/frame/cards-frame';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { SIGNATURE_TYPES, TEMPLATE_VALUES_FORMAT } from '../../config/constants/signatureConstants';
import { FormWrapperPage } from '../formWrapper/form-wrapper';
import { Button } from '../buttons/buttons';
import SignaturePad from '../SignaturePad/SignaturePad';
import constants from '../../config/api/constants';

const SignDocument = ({ documentTemplate, onFinishSign, isDocumentSigned, staticPrefix }) => {
  const { translate: t } = useDictionaryTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [htmlTemplate, setHtmlTemplate] = useState(null);
  const [signatureURL, setSignatureURL] = useState(null);
  const containerHtmlRef = useRef(null);
  const dateNow = new Date();

  const formatDate = (date) => {
    if (!date) return '';
    const numberDate = Number(date);
    const dateToParse = Number.isNaN(numberDate) ? date : numberDate;
    return moment(dateToParse).format('DD MMM YYYY HH:mm:ss');
  };

  function replaceTemplate(html, data) {
    let updatedHtml = html;
    data.templateInputs.forEach(({ id, name, value = "" }) => {
      const inputElement = `<input type="text" id="${id}" name="${name}" value="${value}" ${isDocumentSigned ? 'disabled' : ''} />`;
      const regex = new RegExp(`{{\\s*${id}\\s*}}`, "g");
      updatedHtml = updatedHtml.replace(regex, inputElement);
    });

    data.templateValues.forEach(({ id, name, value }) => {
      const valueToday = TEMPLATE_VALUES_FORMAT[id]?.format ? moment(dateNow).format(TEMPLATE_VALUES_FORMAT[id].format) : "";
      const spanElement = `<span id="${id}" name="${name}">${value || valueToday}</span>`;
      const regex = new RegExp(`{{\\s*${id}\\s*}}`, "g");
      updatedHtml = updatedHtml.replace(regex, spanElement);
    });
    setHtmlTemplate(updatedHtml);
  }


  const onFinish = useCallback(() => {
    if (isDocumentSigned) {
      onFinishSign({isDocumentSigned});
      return
    }

    const dataSign = form.getFieldsValue();
    const { signature } = dataSign;
    delete dataSign.signature;

    const dataHtmlTemplateInputs = [];
    const dataHtmlTemplateValues = [];
    documentTemplate.templateInputs.forEach(({ name }) => {
      containerHtmlRef.current.querySelectorAll(`input[name="${name}"]`).forEach((input) => {
        dataHtmlTemplateInputs.push({
          id: input.id,
          name: input.name,
          value: input.value,
        });
      });
    });

    documentTemplate.templateValues.forEach(({ name }) => {
      containerHtmlRef.current.querySelectorAll(`span[name="${name}"]`).forEach((span) => {
        dataHtmlTemplateValues.push({
          id: span.id,
          name,
          value: span.innerText,
        });
      });
    });

    onFinishSign({
      idDocumentTemplate: documentTemplate._id,
      idDocumentSettings: documentTemplate.idDocumentSettings,
      signer: {
        ...dataSign
      },
      templateInputs: dataHtmlTemplateInputs,
      templateValues: dataHtmlTemplateValues,
      signatureDate: dateNow.toISOString(),
      signature,
    });
  }, [onFinishSign, documentTemplate]);

  useEffect(() => {
    setIsLoading(true);
    if (documentTemplate && documentTemplate.template && isDocumentSigned) {
      replaceTemplate(documentTemplate.template.html, documentTemplate);
      form.setFieldsValue({ ...documentTemplate.signer, signature: documentTemplate.signer.signatureURL });
      setSignatureURL(`${constants.s3Path}/${staticPrefix}/${documentTemplate.signer.signatureURL}`)
      setIsLoading(false);
      return
    }
    if (documentTemplate) {
      replaceTemplate(documentTemplate?.html, documentTemplate);
      setIsLoading(false);
    }
  }, [documentTemplate]);

  useEffect(() => {

    if (containerHtmlRef.current) {
      if (typeof containerHtmlRef.current.setHTML === 'function') {
        containerHtmlRef.current.setHTML(htmlTemplate || '');
      } else {
        containerHtmlRef.current.innerHTML = htmlTemplate || '';
      }
      const inputs = containerHtmlRef.current.querySelectorAll("input");

      const debouncedHandler = debounceFunction((event) => {
        const { name, value } = event.target;
        if (containerHtmlRef.current) {
          containerHtmlRef.current.querySelectorAll(`input[name="${name}"]`).forEach((element) => {
            element.value = value;
          });
        }
      }, 500);

      inputs.forEach((input) => {
        input.addEventListener("input", debouncedHandler);
      });

      return () => {
        inputs.forEach((input) => {
          input.removeEventListener("input", debouncedHandler);
        });
      };
    }
  }, [htmlTemplate]);

  return (
    <>
      <br />

      <Spin spinning={isLoading}>
        <Cards headless>
          <div ref={containerHtmlRef} />
        </Cards>

        <Form name="signDocument" form={form} layout="vertical" onFinish={onFinish}>
          <Cards headless>
            <h3>{formatDate(dateNow)}</h3>
            <FormWrapperPage>
              <Row>
                <Col md={22} sm={24}>
                  <Form.Item name="signatureType" label={t('whoSignDocument')} rules={[{ required: true, message: t('fieldIsRequired') }]}>
                    <Radio.Group
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                      }}
                      disabled={isDocumentSigned}
                      options={SIGNATURE_TYPES.map((option) => ({
                        label: t(option.name),
                        value: option.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24}>
                  <Form.Item name="name" label={t('fullName')} rules={[{ required: true, message: t('nameRequired') }]}>
                    <Input placeholder={t('fullName')} required disabled={isDocumentSigned} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24}>
                  <Form.Item name="identification" label={t('identification')}
                    rules={[
                      { min: 3, required: true, message: t('identificationRequired') },
                      { pattern: /^[a-zA-Z0-9]+$/, message: t('lettersAndNumbersOnly') },
                    ]}
                  >
                    <Input placeholder={t('identification')} required disabled={isDocumentSigned} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24}>
                  <Form.Item name="mobilePhone" label={t('phoneNumber')}>
                    <Input placeholder={t('phoneNumber')} disabled={isDocumentSigned} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24}>
                  <Form.Item name="email" label={t('email')}
                    rules={[
                      { required: false, message: t('emailRequired') },
                      { type: 'email', message: t('isNotAValidEmail', { label: t('email') }) },
                    ]}>
                    <Input placeholder={t('email')} disabled={isDocumentSigned} />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item name="signature" label={t('signature')} rules={[{ required: true, message: t('fieldIsRequired') }]}>
                    {!isDocumentSigned && <p>{t('signDocumentDescription')}</p>}
                    <div style={{ width: '100%', height: 200, marginBottom: 30 }}>
                      <SignaturePad onSave={(value) => { form.setFieldValue('signature', value) }}
                        disabled={isDocumentSigned}
                        signatureURL={signatureURL}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FormWrapperPage>
          </Cards>
          <Row justify="center">
            <Button form="signDocument" htmlType="submit" type="primary" style={{ marginRight: '16px' }}>
              {!isDocumentSigned ? t('save') : t('continue')}
            </Button>
          </Row>

        </Form>
      </Spin>
    </>
  );
};

SignDocument.propTypes = {
  documentTemplate: PropTypes.object,
  onFinishSign: PropTypes.func,
  isDocumentSigned: PropTypes.bool,
  staticPrefix: PropTypes.string,
};

export default SignDocument;
