import actions from './actions';
import { DictionariesService } from '../../services/dictionariesService';
import { AppointmentSelfSchedulingService } from '../../services/appointmentSelfSchedulingService';

const { dictionaryBegin, dictionarySuccess, dictionaryErr } = actions;

const getDictionary = () => {
  return async (dispatch) => {
    try {
      dispatch(dictionaryBegin());
      const dictionary = await DictionariesService.getDictionary();
      dispatch(dictionarySuccess(dictionary));
    } catch (error) {
      dispatch(dictionaryErr(error));
    }
  };
};

const getSelfSchedulingDictionary = () => {
  return async (dispatch) => {
    try {
      dispatch(dictionaryBegin());
      const { dictionary } = await AppointmentSelfSchedulingService.getDictionary();
      const dictionaryData = DictionariesService.transformDictionary(dictionary);
      dispatch(dictionarySuccess(dictionaryData));
    } catch (error) {
      dispatch(dictionaryErr(error));
    }
  };
};

const setDictionary = (dictionary) => {
  return async (dispatch) => {
    try {
      dispatch(dictionarySuccess(dictionary));
    } catch (error) {
      dispatch(dictionaryErr(error));
    }
  };
};

export { getDictionary, getSelfSchedulingDictionary, setDictionary };
