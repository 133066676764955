import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Spin } from 'antd';
import useVideoConsultation from './hooks/useVideoConsultation';
import FieldUrlCustom from '../../../../components/FieldUrlCustom/FieldUrlCustom';
import { useDictionaryTranslation } from '../../../../hooks/useDictionaryTranslation';
import { useNotifications } from '../../../../hooks/useNotifications';

const FieldsUrls = ({ roomData }) => {
  const { translate: t } = useDictionaryTranslation();
  const { createRoom, dataRoom, isLoading } = useVideoConsultation();
  const { showSuccessNotification } = useNotifications();

  const getHandleCopy = (value) => () => {
    navigator.clipboard.writeText(value);
    showSuccessNotification({
      title: 'success',
      description: 'textCopyToClipBoard',
    });
  };

  const { userId } = useParams();

  const userRooms = useMemo(() => {
    return {
      hostRoom: dataRoom?.hostRoom ?? roomData?.hostRoom,
      guestRoom: dataRoom?.guestRoom ?? roomData?.guestRoom,
    };
  }, [dataRoom, roomData]);

  const onCreateRoom = () => {
    createRoom(userId);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <FieldUrlCustom
          textField={userRooms?.hostRoom}
          onchange={onCreateRoom}
          title={t('urlResourcesRoom')}
          textButton={t('Crear')}
        />
        <FieldUrlCustom
          copyMode
          textField={userRooms?.guestRoom}
          title={t('urlFinalCustomerRoom')}
          textButton={t('Copiar')}
          onchange={getHandleCopy}
        />
        <FieldUrlCustom
          copyMode
          textField={userRooms?.hostRoom}
          title={t('urlVideoConsultation')}
          textButton={t('Copiar')}
          onchange={getHandleCopy}
        />
      </Spin>
    </>
  );
};

export default FieldsUrls;

FieldsUrls.propTypes = {
  roomData: PropTypes.object,
};
