import { Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { useDebouncedFunction } from '../../hooks/useDebouncedFunction';

export const DropdownSelect = ({
  value,
  onChange,
  options,
  onSearch: debounceSearch,
  isLoading,
  placeholderText = 'Seleccione una opción',
  getOptionData,
  multiple = false,
  translate = true,
  allowClear = true,
  showSearch = true,
  disabled = false,
  ...rest
}) => {
  const { translate: t } = useDictionaryTranslation();
  const hiddenInputElId = useMemo(() => uuid(), []);
  const [search, setSearch] = useState('');

  const onSearch = (searchText) => setSearch(searchText);

  const searchOptions = useCallback(() => {
    debounceSearch?.(search);
  }, [search, debounceSearch]);

  useDebouncedFunction(searchOptions, 500);

  const dropdownOptions = useMemo(() => {
    const newOptions = [];
    options.forEach((option) => {
      const newOption = getOptionData ? getOptionData(option) : option;
      if (!multiple || !value?.some((item) => item.value === option._id)) {
        if (translate) newOption.label = t(newOption.label);
        newOptions.push(newOption);
      }
    });
    return newOptions;
  }, [options, getOptionData, value, translate, t, multiple]);

  const dropdownSelectedValue = useMemo(() => {
    if (multiple || !value) return value;
    const newOption = getOptionData ? getOptionData(value) : value;
    if (translate) newOption.label = t(newOption.label);
    return newOption;
  }, [value, getOptionData, t, multiple, translate]);

  const selectValue = (...values) => {
    if (onChange) onChange(...values);
  };

  return (
    <Select
      size="middle"
      id={hiddenInputElId}
      allowClear={allowClear}
      // ref={hiddenInputEl}
      labelInValue
      mode={multiple ? 'multiple' : null}
      filterOption={false}
      showSearch={showSearch}
      notFoundContent={isLoading ? <Spin size="sm" /> : null}
      value={dropdownSelectedValue}
      onChange={selectValue}
      onSearch={onSearch}
      placeholder={placeholderText}
      options={dropdownOptions}
      disabled={disabled}
      {...rest}
    />
  );
};

const selectOptionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

DropdownSelect.propTypes = {
  placeholderText: PropTypes.string,
  value: PropTypes.oneOfType([selectOptionShape, PropTypes.arrayOf(selectOptionShape)]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(selectOptionShape),
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  getOptionData: PropTypes.func,
  multiple: PropTypes.bool,
  translate: PropTypes.bool,
  allowClear: PropTypes.bool,
  showSearch: PropTypes.bool,
  disabled: PropTypes.bool,
};
