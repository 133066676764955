import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Admin from '.';
import ProtectedRoute from '../../components/utilities/protectedRoute';
import { useAppRoutes } from '../../hooks/useAppRoutes';
import PendingsBoard from '../../views/PendingsBoard/PendingsBoard';
import { getDictionary } from '../../redux/dictionary/actionCreator';
import { getCustomViewsReports } from '../../redux/customViewsReports/actionCreator';
import { getCustomReports } from '../../redux/customReports/actionCreator';
import { getColorList } from '../../redux/colors/actionCreator';
import { DEFAULT_DOCUMENT_TITLE } from '../../config/constants/accountConstants';
import AccountLockoutModal from '../../components/accountLockoutModal/AccountLockoutModal';

const AuthenticatedApp = () => {
  const dispatch = useDispatch();
  const { defaultRoute } = useAppRoutes();
  const { accountData } = useSelector((state) => {
    return {
      accountData: state.auth.accountData,
    };
  });
  const [isModalVisible, setIsModalVisible] = useState(!accountData?.isAvailable);

  const onCloseModalLockout = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    dispatch(getCustomViewsReports());
    dispatch(getCustomReports());
    dispatch(getDictionary());
    dispatch(getColorList());
  }, [dispatch]);

  useEffect(() => {
    document.title = accountData?.title_web_app?.trim() || DEFAULT_DOCUMENT_TITLE;
  }, [accountData?.title_web_app]);

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={defaultRoute} />} />
          <Route path="/pendings-board" component={PendingsBoard} />
          <ProtectedRoute path="/" component={Admin} />
          <Redirect to={defaultRoute} />
        </Switch>
        {accountData != null ? <AccountLockoutModal show={isModalVisible} onClose={onCloseModalLockout} /> : null}
      </Router>
    </>
  );
};

export default AuthenticatedApp;
