import { Row } from 'antd';
import styled from 'styled-components';

const ContentIframe = styled(Row)`
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

export { ContentIframe };
