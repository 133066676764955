import { Col, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ContentUrl, StyledButton, TitleField, UrlText } from './styled';

const FieldUrlCustom = ({ title, textButton, onchange, textField = '', btnType = 'gray', copyMode = false }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const truncatedText = showFullText ? textField : `${textField?.substring(0, 90)}`;

  return (
    <>
      <Row>
        <Col>
          <TitleField>{title}</TitleField>
        </Col>
        <Col md={24} sm={24}>
          <Row>
            <Col xs={22}>
              <ContentUrl>
                <Tooltip placement="topLeft" title={textField}>
                  <UrlText onClick={toggleShowFullText}>
                    <span> {truncatedText}</span>
                  </UrlText>
                </Tooltip>
              </ContentUrl>
            </Col>

            {!copyMode ? (
              <Col xs={2}>
                <Row justify="end">
                  <StyledButton onClick={onchange} type={btnType}>
                    <span>{textButton}</span>
                  </StyledButton>
                </Row>
              </Col>
            ) : (
              <Col xs={2}>
                <Row justify="end">
                  <StyledButton onClick={onchange(textField)} type={btnType}>
                    <span>{textButton}</span>
                  </StyledButton>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FieldUrlCustom;

FieldUrlCustom.propTypes = {
  title: PropTypes.string,
  textButton: PropTypes.string,
  onchange: PropTypes.func,
  textField: PropTypes.string,
  btnType: PropTypes.string,
  copyMode: PropTypes.bool,
};
