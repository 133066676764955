const termsIds = {
  common: {
    CUSTOMER: 1,
    SERVICE: 2,
    SKILL: 3,
    CONTACT: 4,
    ASSET: 8,
    HEADQUARTER: 12,
    FINAL_CUSTOMER: 13,
    PRIORITY: 14,
    HIGH_PRIORITY: 15,
    MEDIUM_PRIORITY: 16,
    LOW_PRIORITY: 17,
    NO_PRIORITY: 18,
    NOTES: 19,
    REVIEW: 20,
    EXTENSION: 21,
    TOPIC: 22,
    SURVEY: 23,
    VIDEO_CONSULTATION: 24,
  },
  profileNames: {
    ADMIN: 1,
    OPERATOR: 2,
    TECHNICIAN: 4,
    INTERESTED: 5,
  },
};

export const dictionaryTermsKeys = {
  commonTerms: {
    [termsIds.common.CUSTOMER]: {
      key: 'customer',
      keyPlural: 'customerPlural',
    },
    [termsIds.common.SERVICE]: {
      key: 'service',
      keyPlural: 'servicePlural',
    },
    [termsIds.common.SKILL]: {
      key: 'skills',
      keyPlural: 'skillsPlural',
    },
    [termsIds.common.CONTACT]: {
      key: 'contact',
      keyPlural: 'contactPlural',
    },
    [termsIds.common.ASSET]: {
      key: 'asset',
      keyPlural: 'assetPlural',
    },
    [termsIds.common.HEADQUARTER]: {
      key: 'headquarter',
      keyPlural: 'headquarterPlural',
    },
    [termsIds.common.FINAL_CUSTOMER]: {
      key: 'finalCustomer',
      keyPlural: 'finalCustomerPlural',
    },
    [termsIds.common.PRIORITY]: {
      key: 'priority',
      keyPlural: 'priorityPlural',
    },
    [termsIds.common.HIGH_PRIORITY]: {
      key: 'highPriority',
      keyPlural: 'highPriorityPlural',
    },
    [termsIds.common.MEDIUM_PRIORITY]: {
      key: 'mediumPriority',
      keyPlural: 'mediumPriorityPlural',
    },
    [termsIds.common.LOW_PRIORITY]: {
      key: 'lowPriority',
      keyPlural: 'lowPriorityPlural',
    },
    [termsIds.common.NO_PRIORITY]: {
      key: 'notPrioritary',
      keyPlural: 'notPrioritaryPlural',
    },
    [termsIds.common.NOTES]: {
      key: 'note',
      keyPlural: 'notePlural',
    },
    [termsIds.common.REVIEW]: {
      key: 'review',
      keyPlural: 'reviewPlural',
    },
    [termsIds.common.EXTENSION]: {
      key: 'extension',
      keyPlural: 'extensionPlural',
    },
    [termsIds.common.TOPIC]: {
      key: 'topic',
      keyPlural: 'topicPlural',
    },
    [termsIds.common.SURVEY]: {
      key: 'survey',
      keyPlural: 'surveyPlural',
    },
    [termsIds.common.VIDEO_CONSULTATION]: {
      key: 'videoConsultation',
      keyPlural: 'videoConsultationPlural',
    },
  },
  profileNames: {
    [termsIds.profileNames.ADMIN]: {
      key: 'admin',
      keyPlural: 'adminPlural',
    },
    [termsIds.profileNames.OPERATOR]: {
      key: 'operator',
      keyPlural: 'operatorPlural',
    },
    [termsIds.profileNames.TECHNICIAN]: {
      key: 'technician',
      keyPlural: 'technicianPlural',
    },
    [termsIds.profileNames.INTERESTED]: {
      key: 'interested',
      keyPlural: 'interestedPlural',
    },
  },
};
