import { useCallback, useEffect } from 'react';
import { Col, Form, InputNumber, Modal, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { Button } from '../../../components/buttons/buttons';
import { FormWrapper } from '../../../components/formWrapper/form-wrapper';
import { usePendingsBoard } from '../hooks/usePendingsBoard';

export const RotationTimeModal = ({ show, handleClose }) => {
  const [form] = Form.useForm();
  const { translate: t } = useDictionaryTranslation();
  const { settingData, updateBoardSettings, isLoading } = usePendingsBoard();
  const minRotationTime = 5;

  const closeModal = () => {
    
    handleClose();
  };

  const setSettingData = useCallback(() => {
    form.setFieldsValue({
      rotationTime: settingData?.rotationTime,
    });
  }, [form, settingData]);

  useEffect(() => {

    setSettingData();
  }, [setSettingData]);

  const handleSubmit = async () => {
    const formValues = form.getFieldsValue();
    const data = {
      rotationTime: formValues.rotationTime,
    };
    await updateBoardSettings(data);
    handleClose(true);
  };

  return (
    <Modal
      title={t('rotationTime')}
      destroyOnClose
      width="40%"
      height="50%"
      onCancel={closeModal}
      open={show}
      footer={
        <div>
          <Button form="rotationTimeForm" key="submit" htmlType="submit" type="primary">
            {t('save')}
          </Button>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <FormWrapper>
          <Form name="rotationTimeForm" layout="vertical" form={form} onFinish={handleSubmit}>
            <Row>
              <Col xs={24} md={24}>
                <Form.Item
                  labelCol={{ span: 12 }}
                  name="rotationTime"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject(t('rotationTimeRequired'));
                        }
                        if (value < minRotationTime) {
                          return Promise.reject(t('minRotationTime', { n: minRotationTime }));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label={t('rotationTimeMinutes')}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </Spin>
    </Modal>
  );
};

RotationTimeModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
};
