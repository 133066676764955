import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { Button } from '../../../components/buttons/buttons';
import { FormWrapper } from '../../../components/formWrapper/form-wrapper';
import { ModalColumns } from '../Style';
import { usePendingsBoard } from '../hooks/usePendingsBoard';
import { SelectServiceType } from '../../../components/services/forms/SelectServiceType';

export const ServiceTypeFilterModal = ({ show, handleClose }) => {
  const [form] = Form.useForm();
  const { translate: t } = useDictionaryTranslation();
  const { updateBoardSettings, settingData } = usePendingsBoard();
  const [serviceState, setServiceState] = useState([]);

  const closeModal = () => {
    handleClose();
  };

  const handleSubmit = async () => {
    const formValues = form.getFieldsValue();
    const data = {
      serviceTypes: formValues.serviceTypes.map((value) => value.value),
    };
    await updateBoardSettings(data);
    handleClose(true);
  };

  useEffect(() => {
    const serviceTypes = settingData?.serviceTypes?.map((value) => ({ value: value._id, label: value.name }));
    setServiceState(serviceTypes);
  }, [settingData]);

  const onSelectServiceTypes = (items) => {
    setServiceState(items);
  };

  return (
    <ModalColumns
      title={t('filters')}
      destroyOnClose
      width="25%"
      height="50%"
      onCancel={closeModal}
      open={show}
      footer={null}
    >
      <FormWrapper>
        <Form name="filterform" layout="vertical" form={form} onFinish={handleSubmit}>
          <Row>
            <Col xs={24} md={24}>
              <Form.Item name="serviceTypes">
                <SelectServiceType selectedServiceType={serviceState} onChange={onSelectServiceTypes} multiple />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Button form="filterform" key="submit" htmlType="submit" type="primary">
              {t('save')}
            </Button>
          </Row>
        </Form>
      </FormWrapper>
    </ModalColumns>
  );
};

ServiceTypeFilterModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  filters: PropTypes.shape({
    serviceTypes: PropTypes.array,
  }),
};
