import React, { useEffect, useState, useCallback } from 'react';
import { Col, Spin } from 'antd';
import { useParams, Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import { useVideoCall } from './hooks/useVideoCall';
import { HeaderImages, HeaderContainer } from './Style';
import { useNotifications } from '../../hooks/useNotifications';
import { Main } from '../../container/styled';
import SignDocument from '../../components/signDocument/SignDocument';
import constants from '../../config/api/constants';

const VideoCall = () => {
  const params = useParams();
  const { videoCallData, isLoading, signVideoCallDocument } = useVideoCall({ idService: params.idService });
  const [coords, setCoords] = useState(null);
  const [redirectConsultation, setRedirectConsultation] = useState(false);
  const { showErrorNotification } = useNotifications();

  const onFinishSign = useCallback(
    async (data) => {
      if (data?.isDocumentSigned) {
        setRedirectConsultation(true);
        return;
      }

      const { signedDocument } = await signVideoCallDocument({
        ...data,
        signatureLocation: coords,
      });
      if (signedDocument && signedDocument._id) {
        setRedirectConsultation(true);
      } else {
        showErrorNotification({
          title: 'error',
          description: 'errorSignDocument',
        });
      }
    },
    [coords, signVideoCallDocument],
  );

  const showPosition = (data) => {
    setCoords({ latitude: data.coords.latitude, longitude: data.coords.longitude });
  };

  const showErrorGeolocalization = () => {
    showErrorNotification({
      title: 'error',
      description: 'errorRetrievingGeolocation',
    });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showErrorGeolocalization);
    }
  }, []);

  if (!isLoading && !videoCallData?.isVideoCallEnabled) {
    return <Redirect to="/" />;
  }

  if (!isLoading && (!videoCallData?.requiresDocument || redirectConsultation)) {
    const username = videoCallData?.assignedUserData?.username
      ? videoCallData?.assignedUserData?.username.split('.').pop()
      : '';
    return <Redirect to={`/video-consultation/${videoCallData?.accountData?.staticPrefix}/${username}`} />;
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <HeaderContainer>
          <HeaderImages>
            <img
              className="account-logo"
              src={
                videoCallData?.accountData?.menuLogoPlatform && videoCallData?.accountData?.staticPrefix
                  ? `${constants.s3Path}/${videoCallData.accountData.staticPrefix}/${videoCallData.accountData.menuLogoPlatform}`
                  : `${constants.s3UrlPath}/suite_assets/logo_suite.png`
              }
              alt="Menu Logo"
            />
          </HeaderImages>
        </HeaderContainer>

        <Main>
          <Col xs={24} style={{ margin: '10px 10%' }}>
            <SignDocument
              onFinishSign={onFinishSign}
              documentTemplate={videoCallData?.documentTemplate || videoCallData?.signedDocument}
              isDocumentSigned={videoCallData?.isDocumentSigned}
              staticPrefix={videoCallData?.accountData?.staticPrefix}
            />
          </Col>
        </Main>
      </Spin>
    </>
  );
};

export default VideoCall;
