import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class videoConsultationService {
  static async getRoom(idUser) {
    const { data } = await DataService.get(`${API.videoConsultation.root}/${idUser}`);
    return {
      videoConsultationRoom: data.videoConsultationRoom,
    };
  }

  static async saveRoom(idUser, roomData) {
    const { data } = await DataService.patch(`${API.videoConsultation.root}/${idUser}`, roomData);
    return {
      videoConsultationRoom: data.videoConsultationRoom,
    };
  }

  static async createRoom(idUser) {
    const params = {
      idUser,
    };
    const { data } = await DataService.post(`${API.videoConsultation.root}`, params);
    return {
      userRoomData: data.userRoomData,
    };
  }

  static async getPublicRoom({ accountPrefix, username }) {
    const dataRoom = await DataService.get(`${API.videoConsultation.root}${API.videoConsultation.publicRoom}`, {
      accountPrefix,
      username,
    });
    return {
      dataRoom: dataRoom.data,
    };
  }
}

export { videoConsultationService };
