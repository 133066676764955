import { useCallback } from 'react';
import { Col, Collapse, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import FieldsUrls from './fieldsUrls/FieldsUrls';
import ConsultationParameters from './consultationParameters/ConsultationParameters';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';

const FormTeleconsultation = ({ roomData, onChangeRoomData }) => {
  const { translate: t } = useDictionaryTranslation();
  const Text = Typography;
  const { Panel } = Collapse;

  const onChangeParams = useCallback(
    (newParams) => {
      onChangeRoomData({ params: newParams });
    },
    [onChangeRoomData],
  );

  return (
    <>
      <Row>
        <Col xs={24} md={24}>
          <Collapse style={{ width: '100%' }} className="collapse">
            <Panel
              style={{ width: '100%' }}
              header={
                <Row>
                  <Col xs={12}>
                    <Text style={{ fontSize: '14px' }}>{t('videoConsultationConfiguration')}</Text>
                  </Col>
                </Row>
              }
              key="1"
            >
              <FieldsUrls roomData={roomData} onChange />
              <Row>
                <ConsultationParameters params={roomData?.params} onChange={onChangeParams} />
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default FormTeleconsultation;

FormTeleconsultation.propTypes = {
  roomData: PropTypes.object,
  onChangeRoomData: PropTypes.func,
};
