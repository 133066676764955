import { Button } from 'antd';
import styled from 'styled-components';

const ContentUrl = styled.div`
  border-radius: 10px;
  border: 2px #d6dce3 solid;
  background-color: #f4f5f7;
  padding: 10px;
  margin-bottom: 20px;
  min-height: 40px;
`;

const UrlText = styled.span`
  border-radius: 10px;
  word-wrap: break-word;
  color: #aeb4c1;
`;

const TitleField = styled.span`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  background: #9299b8;
  width: 70px;
  height: 40px;
  color: white;
`;

export { UrlText, ContentUrl, TitleField, StyledButton };
