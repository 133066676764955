import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  background-color: #ffc008;
  border-radius: 50%;
  padding: 10px;
`;

const Message = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

export { IconWrapper, Message };
