

export const pendingsBoardsColumns = {
  assignedDate: { name: 'assignedDate', },
  assignedUser: { name: 'technician' },
  customer: { name: 'cutomer' },
  id: { name: 'id' },
  name: { name: 'name' },
  serviceType: { name: 'serviceType' },
  status: { name: 'status' },
  serviceTags: { name: 'serviceTags' },
  creationDate: { name: 'creationDate' },
  finalCustomer: { name: 'finalCustomer' },
} 